$size: 30px;
$sizeFicha: 60px;
$rowColor: #FFF;

.product-info__short {
    .product-icons {
        &__icon {
            //min-height: $sizeFicha;

            &__image {
                img {
                    max-height: $sizeFicha;
                    max-width: $sizeFicha;
                }
            }

            &__text,
            &__image {
                &.with-pdf {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}

.product-icons {
    &__icon {
        display: flex;
        //min-height: $size;
        align-items: center;

        i {
            margin: 3px 3px 3px 0;
            display: inline-block;
            padding-right: 5px;
        }

        &__text {
            border: 1px solid #000;
            background-color: #FFF;
            padding: 2px 5px;
            font-size: 12px;
        }

        &__image {
            img {
                max-height: $size;
                max-width: $size;
            }
        }
    }
}

.pdf-modal {
    width: 100%;
    left: auto;
    right: 0;
    max-width: 680px;

    .modal-inner-wrap {
        padding-top: 60px;
    }

    .modal-header {
        position: relative;
        display: flex;
    }

    .action-close {
        position: static;
        order: 10;
        margin-left: auto;
    }

    .page-main-actions {
        margin: 0;
        order: 5;
    }

    .modal-content {
        padding-top: 1.3rem;
        flex-grow: 1;
    }

    .pdfModalContainer {
        height: 100%;

        object,
        embed {
            width: 100%;
            height: 100%;
        }
    }
}

.pdf-table {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    &__row {
        display: flex;
        background-color: $rowColor;

        &--header {
            border-bottom: 1px solid #000;
        }

        &--even {
            background-color: darken($rowColor, 10%);
        }
    }

    &__col {
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;
    }
}
